.container {
  margin: 0 auto;
  margin-bottom: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}

.header {
  padding: 30px 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: center;
  align-items: center;

  p {
    margin: 0;
  }
}

.logo {
  align-self: center;
  background-color: rgb(29 161 242);
  height: 150px;
  width: 150px;
  border-radius: 150px;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    width: 100px;
    object-fit: cover;
  }
}

.telegram {
  margin: 0;
  font-size: 24px;
  font-weight: 900;
}

.back {
  position: absolute;
  left: 20px;
  top: 20px;
}

@media screen and (max-width: 450px) {
  .telegram {
    font-size: 20px;
  }
  .join {
    font-size: 16px;
    padding: 10px 20px;
  }
  .back {
    font-size: 14px;
    font-weight: 600;
    padding: 10px 20px;
  }
}

@media screen and (max-width: 350px) {
  .telegram {
    font-size: 18px;
  }
}