.container {
  display: flex;
  flex-direction: row;
  gap: 20px;
}
.top {
  display: flex;
  gap: 20px;
  margin-bottom: 10px;
}
.photo {
  background-color: var(--blue-color);
  width: 70px;
  height: 70px;
  border-radius: 10px;
  object-fit: cover;
  display: none;
}
.photo_desktop {
  background-color: var(--blue-color);
  width: 200px;
  height: 200px;
  border-radius: 10px;
  object-fit: cover;
}
.name {
  color: var(--blue-color);
}
.text {
  text-align: start;
}

@media screen and (max-width: 1000px){
  .photo {
    display: block;
  }
  .photo_desktop {
    display: none;
  }
}