:root {
  --blue-color: rgb(29 161 242);
}
body {
  margin: 0;
  font-family: "Geologica", sans-serif;
  color: rgb(20 23 26);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #F5F8FA;
}
button {
  font-family: "Geologica", sans-serif;
}
p {
  margin: 0;
}
input {
  font-family: "Geologica", sans-serif;
}
textarea {
  font-family: "Geologica", sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.react-pdf__message {
  display: none;
}
