.container {
  width: 1000px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}
@media screen and (max-width: 1050px){
  .container {
    max-width: 80vw;
  }
}