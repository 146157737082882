.container {
  background-color: white;
  color: #03b203;
  padding: 20px 40px;
  position: fixed;
  right: 50px;
  top: -100px;
  transition: all 0.3s ease;
  border-radius: 20px;
  font-weight: 900;
}
.visible {
  top: 50px;
}

.error {
  color: #dc5656;
}
@media screen and (max-width: 800px){
  .container {
    right: 10vw;
    left: 10vw;
  }
}