.content {
  max-width: 800px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.join {
  color: black;
  text-decoration: none;
  width: 700px;
  cursor: pointer;
  align-self: center;
  font-weight: 900;
  font-size: 18px;
  background-color: transparent;
  border: none;
  border-radius: 30px;
  padding: 20px 40px;
  box-shadow: 0 3px 3px 1px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s ease;
}
.join:hover {
  box-shadow: 0 4px 3px 2px rgba(0, 0, 0, 0.2);
}

.error {
  color: #dc6363;
}
.loading {
  width: 100px;
}

@media screen and (max-width: 850px){
  .content {
    max-width: 80vw;
  }
  .join {
    width: 100%;
  }
}
@media screen and (max-width: 450px){
  .join {
    font-size: 16px;
    padding: 10px 20px;
  }
}