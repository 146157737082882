.loadingOverlay {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 28px;
  z-index: 1;
}
.container {
  display: flex;
  align-self: center;
  align-items: center;
  justify-content: center;
  margin: auto;
  width: 100%;
  overflow: hidden;
}

.loader {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 1.5rem;
  color: #fff;
  background: rgba(0, 0, 0, 0.7);
  padding: 1rem;
  border-radius: 0.5rem;
}
.download {
  margin: auto;
  color: rgb(20 23 26);
  text-decoration: none;
  cursor: pointer;
  font-weight: 900;
  font-size: 18px;
  background-color: transparent;
  border: none;
  border-radius: 30px;
  padding: 20px 40px;
  box-shadow: 0 3px 3px 1px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s ease;
}
.download:hover {
  box-shadow: 0 4px 3px 2px rgba(0, 0, 0, 0.2);
}
.window {
  padding: 20px 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
}
.buttons {
  display: flex;
  flex-direction: row;
  gap: 10px;
  flex-wrap: wrap;
}
.swipe {
  font-size: 14px !important;
  font-weight: 600 !important;
  padding: 10px 20px !important;
  margin: 0 10px !important;
}
.back {
  position: absolute;
  left: 20px;
  top: 20px;
  z-index: 100;
}

@media screen and (max-width: 820px) {
  .window {
    padding: 50px 0;
  }
  .back {
    font-size: 14px;
    font-weight: 600;
    padding: 10px 20px;
  }
}
@media (max-width: 768px) {
  .flipbook {
    width: 100%;
    height: auto;
  }
}
@media (max-width: 500px) {
  .container {
    height: 400px;
  }
}
@media (max-width: 820px) {
  .container {
    height: 600px;
  }
}
