.button {
  margin: auto;
  color: rgb(20 23 26);
  text-decoration: none;
  cursor: pointer;
  font-weight: 900;
  font-size: 18px;
  background-color: transparent;
  border: none;
  border-radius: 30px;
  padding: 20px 40px;
  box-shadow: 0 3px 3px 1px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s ease;
}
.button:hover {
  box-shadow: 0 4px 3px 2px rgba(0, 0, 0, 0.2);
}