.container {
  min-height: 50px;
  width: 100%;
  background-color: rgb(20 23 26);
  padding: 10px;
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  justify-content: start;
  gap: 30px;
  align-items: center;
  align-self: center;
  color: white;
  font-weight: 600;
  font-size: 16px;
  animation: reset-scale 0.3s ease forwards;
  text-decoration: none;
  text-align: start;
  button {
    cursor: pointer;
    align-self: center;
    background-color: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    height: 20px;
    transition: transform 0.3s ease;

    img {
      height: 20px;
    }
  }
}
.container:hover {
  animation: grow-shrink-grow 0.3s ease forwards;
  z-index: 1;
}

@keyframes grow-shrink-grow {
  0% {
    transform: scale(1); /* Исходное состояние */
  }
  50% {
    transform: scale(1.05); /* Увеличение */
  }
  100% {
    transform: scale(1.04); /* Окончательное увеличение */
  }
}
@keyframes reset-scale {
  from {
    transform: scale(1.04);
  }
  to {
    transform: scale(1);
  }
}

.cover {
  align-self: center;
  height: 50px;
  width: 50px;
  object-fit: cover;
  border-radius: 10px;
}
@media screen and (max-width: 450px){
  .container {
    font-size: 14px;
  }
}