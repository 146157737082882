.container {
  width: 800px;
  margin: 0 auto;
}
.form_container {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.input_container {
  input {
    width: 100%;
    font-size: 16px;
    background-color: transparent;
    border: none;
    border-bottom: 1px solid #8f91a4;
    padding: 10px 0 10px;
  }
  input:focus {
    outline: none;

  }
}
.request {
  box-sizing: border-box;
  padding: 10px;
  width: 100%;
  border: none;
  background-color: white;
  border-radius: 10px;
  resize: none;
  min-height: 130px;
  font-size: 16px;
}
.request:focus {
  outline: none;
}
.error {
  border-bottom: 1px solid #dc5656 !important;
}
@media screen and (max-width: 850px){
  .container {
    max-width: 80vw;
  }
}