.container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  text-align: start;
}
.text {
  font-size: 16px;
  font-weight: 400;
  line-height: 19.36px;
}
.text_feedback {
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.name {
  font-size: 20px;
  font-weight: 400;
  line-height: 24.2px;
}