.container {
  display: flex;
  flex-direction: column;
  gap: 15px;
  width: 800px;
}

.input_container {
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  justify-content: space-between;
  text-align: start;
  input {
    font-size: 16px;
    background-color: transparent;
    border: none;
    border-bottom: 1px solid #8f91a4;
    padding: 10px 0 10px;
    width: 100%;
  }
  input:focus {
    outline: none;

  }
}
.feedback {
  box-sizing: border-box;
  padding: 10px;
  width: 100%;
  border: none;
  background-color: white;
  border-radius: 10px;
  resize: none;
  min-height: 130px;
  font-size: 16px;
}
.feedback:focus {
  outline: none;
}
.error {
  border-bottom: 1px solid #dc5656 !important;
}
@media screen and (max-width: 850px){
  .container {
    max-width: 80vw;
  }
}
