.content {
  width: 800px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.error {
  color: #dc6363;
}
.loading {
  width: 100px;
}

@media screen and (max-width: 850px){
  .content {
    max-width: 80vw;
  }
}